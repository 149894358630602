export const useDrawers = () => {
  const { openList } = storeToRefs(useDrawerStore());

  const open = (name: string) => {
    if (!openList.value?.includes(name)) {
      openList.value?.push(name);
    }
  };

  const close = (name?: string) => {
    if (typeof name === 'string') {
      openList.value = openList.value?.filter((drawerName: string) => drawerName !== name);
    } else if (openList.value?.length) {
      openList.value?.shift();
    }
  };

  return {
    open,
    close,
  };
};
